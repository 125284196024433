<template>
  <div>
    <div v-if="!connected" class="mt-4">
      <div class="font-600 pre-md-text mb-1">Click connect to get started</div>
      <div class="small-text gray-600-color">(pop-ups must be enabled)</div>
    </div>
    <connection-buttons
      v-if="!isUser"
      :connected="connected"
      @connect="oauthIntegration"
      @disconnect="$emit('disconnect')"
    />
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import ConnectionButtons from './ConnectionButtons.vue';

export default {
  components: {ConnectionButtons},
  props: {
    type: String,
    connected: Boolean,
  },
  data: function() {
    return {
      isUser: false,
      apiKey: '',
      email: '',
    };
  },
  computed: {
    ...mapGetters([
      'atsIntegrationAccountId',
    ]),
  },
  methods: {
    connect: async function() {
      if (await this.$refs.validator.validate()) {
        const payload = {
          atsType: 'jobadder',
          atsApiCredentials: {
            atsUserType: 'admin',
          },
          atsIntegrationEnabled: true,
        };
        this.$emit('test-connection', {payload});
      }
    },
    oauthIntegration: function() {
      this.$emit('oauth-integration', this.type);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/lbd/mixins/_layout.scss";

.wrapper-container {
  height: 35px;
  @include respond-to(xs) {
    height: 60px;
  }
  @include respond-to(sm) {
    height: 60px;
  }
}
</style>
