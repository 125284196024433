<template>
  <div class="pl-3">
    <div v-if="!connected" class="mb-4">
      <base-switch
        v-model="on"
        offText="Off"
        onText="On"
      >
      </base-switch>
    </div>
    <ValidationObserver ref="validator" v-slot="{ invalid }">
      <template v-if="!connected">
        <base-input
          v-model="clientId"
          :disabled="!on"
          label="Enter your Client ID"
          name="clientId"
          rules="customRequired"
        >
        </base-input>
        <base-input
          v-model="clientSecret"
          :disabled="!on"
          label="Enter your Client secret"
          name="clientSecret"
          rules="customRequired"
          type="text"
        >
        </base-input>
      </template>
      <connection-buttons
        :connected="connected"
        :disabled="!on || invalid"
        @connect="connect"
        @disconnect="$emit('disconnect')"
      />
    </ValidationObserver>
  </div>
</template>

<script>
import ConnectionButtons from './ConnectionButtons.vue';
import {mapGetters} from 'vuex';

export default {
  components: {ConnectionButtons},
  props: {
    type: String,
    connected: Boolean,
  },
  data: function() {
    return {
      on: false,
      clientSecret: '',
      clientId: '',
    };
  },
  computed: {
    ...mapGetters([
      'userId',
    ]),
  },
  methods: {
    connect: async function() {
      if (await this.$refs.validator.validate()) {
        const payload = {
          atsIntegrationEnabled: this.on,
          atsType: this.type,
          atsApiCredentials: {
            clientSecret: this.clientSecret,
            clientId: this.clientId,
          },
          atsIntegrationEnabled: true,
        };
        this.$emit('test-connection', {payload});
      }
    },
  },
};
</script>
