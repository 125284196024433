<template>
  <div class="pl-3">
    <div class="mb-4">
      <base-switch
        v-if="!connected"
        v-model="on"
        offText="Off"
        onText="On"
      >
      </base-switch>
    </div>
    <ValidationObserver ref="validator" v-slot="{ invalid }">
      <base-input
        v-if="!connected"
        v-model="apiKey"
        :disabled="!on"
        label="Enter your API key"
        name="API key"
        rules="customRequired"
        type="text"
      >
      </base-input>
      <connection-buttons
        :connected="connected"
        :disabled="!on || invalid"
        @connect="connect"
        @disconnect="$emit('disconnect')"
      />
    </ValidationObserver>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import {ATS_INTEGRATION_CONNECTION_REQUEST} from '@/store/storeActions';
import ConnectionButtons from './ConnectionButtons.vue';

export default {
  components: {ConnectionButtons},
  props: {
    type: String,
    connected: Boolean,
  },
  data: function() {
    return {
      on: false,
      apiKey: '',
    };
  },
  computed: {
    ...mapGetters([
      'userEmail',
    ]),
  },
  methods: {
    ...mapActions([
      ATS_INTEGRATION_CONNECTION_REQUEST,
    ]),
    connect: async function() {
      if (await this.$refs.validator.validate()) {
        const payload = {
          atsType: this.type,
          atsApiCredentials: {
            apiKey: this.apiKey,
            emailAddress: this.userEmail,
          },
          atsIntegrationEnabled: true,
        };
        this.$emit('test-connection', {payload});
      }
    },
  },
};
</script>
