<template>
  <div class="pl-3">
    <div v-if="!connected" class="mb-4">
      <base-switch
        v-model="on"
        offText="Off"
        onText="On"
      >
      </base-switch>
    </div>
    <ValidationObserver ref="validator" v-slot="{ invalid }">
      <template v-if="!connected">
        <base-input
          v-model="companyId"
          :disabled="!on"
          label="Enter your Company ID"
          name="Company ID"
          rules="customRequired"
          type="text"
        >
          <template v-slot:bottomLabel>
            <div class="d-flex align-items-center">
              <i class="mr-2 fa fa-info-circle"></i>
              <div class="pre-small-text">
                <span>You can find all your API details under </span>
                <a href="https://app.recruitee.com/#/settings/api_tokens" rel="nofollow noopener noreferrer"
                   target="_blank"><u>Settings &gt; Apps and plugins &gt; Personal API tokens</u></a>.
              </div>
            </div>
          </template>
        </base-input>
        <base-input
          v-model="domain"
          :disabled="!on"
          inputAdd=".recruitee.com"
          label="Enter your Recruitee Subdomain"
          name="Domain"
          rules="customRequired:Your Recruitee domain is required"
        >
          <template v-slot:bottomLabel>
            <div class="d-flex align-items-center">
              <i class="mr-2 fa fa-info-circle"></i>
              <span class="pre-small-text">
              If your Recruitee subdomain is "keeyora.recruitee.com" then "keeyora" is your subdomain name
            </span>
            </div>
          </template>
        </base-input>
        <base-input
          v-model="apiKey"
          :disabled="!on"
          label="Enter your API key"
          name="API key"
          rules="customRequired"
          type="text"
        >
          <template v-slot:bottomLabel>
            <div class="d-flex align-items-center">
              <i class="mr-2 fa fa-info-circle"></i>
              <div class="pre-small-text">
              <span>
                Click on settings from bottom-left corner and find Apps and plugins >  Personal API tokens
                in Recruitee to get your API key. </span>
                <a :href="helpUrl" target="blank"><u>Not able to find?</u></a>
              </div>
            </div>
          </template>
        </base-input>
      </template>
      <connection-buttons
        :connected="connected"
        :disabled="!on || invalid"
        @connect="connect"
        @disconnect="$emit('disconnect')"
      />
    </ValidationObserver>
  </div>
</template>

<script>
import ConnectionButtons from './ConnectionButtons.vue';
import {supportUrl} from '@/store/userConstants';

export default {
  components: {
    ConnectionButtons,
  },
  props: {
    type: String,
    connected: Boolean,
  },
  data: function() {
    return {
      on: false,
      companyId: '',
      apiKey: '',
      domain: '',
      helpUrl: `${supportUrl}6467170-finding-your-recruitee-api-key`,
    };
  },
  methods: {
    connect: async function() {
      if (await this.$refs.validator.validate()) {
        const payload = {
          atsIntegrationEnabled: this.on,
          atsType: this.type,
          atsApiCredentials: {
            companyId: this.companyId,
            apiKey: this.apiKey,
            domain: this.domain,
          },
          // atsIntegrationEnabled: true,
        };
        this.$emit('test-connection', {payload});
      }
    },
  },
};
</script>
