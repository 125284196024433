<template>
  <div>
    <div v-if="!connected" class="mt-4">
      <div class="font-600 pre-md-text mb-1">Click connect to get started</div>
      <div class="small-text gray-600-color">(pop-ups must be enabled)</div>
    </div>
    <connection-buttons
      :connected="connected"
      @connect="oauthIntegration"
      @disconnect="$emit('disconnect')"
    />
  </div>
</template>
<script>
import ConnectionButtons from './ConnectionButtons.vue';

export default {
  components: {ConnectionButtons},
  props: {
    type: String,
    connected: Boolean,
  },
  methods: {
    oauthIntegration: function() {
      this.$emit('oauth-integration', this.type);
    },
  },
};
</script>
